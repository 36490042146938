<script>
/**
 * name：
 * user：sa0ChunLuyu
 * date：2022-02-23 16:57:20
 */
export default {
  props: ['data'],
  data() {
    return {
      x_arr: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"],
      max_x: 0,
      table_show: false,
      table_data: {
        xc: 10,
        yc: 10,
        x: {0: 100, 2: 120, 3: 130},
        y: {0: 24, 2: 50},
        c: {
          0: {0: '0', 2: '2', 3: '3', 4: '4'},
          2: {0: '0', 2: '2', 3: '3', 4: '4'},
          3: {0: '0', 2: '2', 3: '3', 4: '4'},
        }
      },
    }
  },
  mounted() {
    this.makeTable()
  },
  methods: {
    makeTable() {
      let table = {};
      let table_x = {};
      let table_y = {};
      let max_x = 0;
      for (let i = 0; i < this.table_data.yc; i++) {
        table[i] = {}
        let x = typeof this.table_data.x[i] !== 'undefined' ? this.table_data.x[i] : 80
        table_x[i] = x
        max_x = max_x + x;
        table_y[i] = typeof this.table_data.y[i] !== 'undefined' ? this.table_data.y[i] : 20
        for (let j = 0; j < this.table_data.xc; j++) {
          table[i][j] = typeof this.table_data.c[i] !== 'undefined' && typeof this.table_data.c[i][j] !== 'undefined' ? this.table_data.c[i][j] : ''
        }
      }
      this.max_x = max_x;
      this.table_data.c = table;
      this.table_data.x = table_x;
      this.table_data.y = table_y;
      console.log(this.table_data)
      this.table_show = true
    }
  }
}
</script>
<template>
  <div>
    <div v-if="table_show">
      <table>

      </table>
    </div>
  </div>
</template>
<style scoped>
.x_title_wrapper {
  padding-left: 20px;
  display: flex;
}

.x_title_item_wrapper {
  background: #eeeeee;
  text-align: center;
  height: 20px;
  line-height: 20px;
  width: 20px;
}

.y_item_wrapper {
  background: #eeeeee;
  text-align: center;
  height: 20px;
  line-height: 20px;
  width: 20px;
}

.line_wrapper {
  display: flex;
}

.item_wrapper {
  border-right: 1px solid #00a083;
}
</style>
