<script>
/**
 * name：
 * user：sa0ChunLuyu
 * date：2022-02-23 16:52:32
 */
import sTable from '../../../components/sa0/table/table'

export default {
  components: {sTable},
  data() {
    return {
      table_data: {
        x: [],
        y: [],
      },
    }
  },
  mounted() {
  },
  methods: {}
}
</script>
<template>
  <div>
    <sTable :data="table_data"></sTable>
  </div>
</template>
<style scoped>

</style>
